import "core-js/modules/es6.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    tournament: Object
  },
  components: {
    'app-tournaments-prizes': function appTournamentsPrizes() {
      return import(
      /* webpackChunkName: "app-tournaments-prizes" */
      '@/components/Tournaments/TournamentPrizesModal.vue');
    }
  },
  data: function data() {
    return {
      showMorePrizes: false,
      isOpenPrizersModal: false
    };
  },
  computed: {
    visiblePrizes: function visiblePrizes() {
      if (this.tournament.prizes) return this.tournament.prizes.slice(0, 5);
      return null;
    }
  },
  methods: {
    showOtherWinner: function showOtherWinner() {
      this.showMorePrizes = !this.showMorePrizes;
      this.$emit('more-prizes');
    },
    openPrizersModal: function openPrizersModal() {
      this.isOpenPrizersModal = true;
    }
  }
};