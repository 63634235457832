import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Base from '@/mixins/Base';
import TournamentMixin from '@/mixins/TournamentMixin';
import TournamentInfo from '@/components/Tournaments/TournamentInfo.vue';
import TournamentPrizes from '@/components/Tournaments/TournamentPrizes';
export default {
  props: {
    tournament: Object,
    index: Number
  },
  components: {
    'app-info': TournamentInfo,
    'app-prizes': TournamentPrizes
  },
  mixins: [TournamentMixin, Base],
  data: function data() {
    return {
      showMorePrizes: false,
      showItem: this.index === 0
    };
  },
  methods: {}
};